function getPrevSibling(elem, selector) {
  let sibling = elem.previousElementSibling;
  if (!selector) return sibling;
  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.previousElementSibling;
  }
}
global.getPrevSibling = getPrevSibling;
window.getPrevSibling = getPrevSibling;

function getNextSibling(elem, selector) {
  let sibling = elem.nextElementSibling;
  if (!selector) return sibling;
  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }
}
global.getNextSibling = getNextSibling;
window.getNextSibling = getNextSibling;

function getAllNextSiblings(elem, selector) {
  const parent = elem.parentNode;
  const children = parent.children;
  if (!selector) return children;

  let isNextSibling = false;
  const siblings = [];

  for (const child of children) {
    if (child.matches(selector) && isNextSibling) {
      siblings.push(child);
    }
    if (elem === child) {
      isNextSibling = true;
    }
  }
  return siblings;
}
global.getAllNextSiblings = getAllNextSiblings;
window.getAllNextSiblings = getAllNextSiblings;

function getAllPrevSiblings(elem, selector) {
  const parent = elem.parentNode;
  const children = parent.children;
  if (!selector) return children;

  let isPrevSibling = true;
  const siblings = [];

  for (const child of children) {
    if (elem === child) {
      isPrevSibling = false;
    }
    if (child.matches(selector) && isPrevSibling) {
      siblings.push(child);
    }
  }
  return siblings;
}
global.getAllPrevSiblings = getAllPrevSiblings;
window.getAllPrevSiblings = getAllPrevSiblings;
