document.addEventListener('turbolinks:load', function() {
  document.querySelectorAll('[data-imgs-banner="modal-close"]').forEach(closeBtn => { closeImgsBannerModal(closeBtn); });

  document.querySelectorAll('[data-imgs-banner="img"]').forEach(img => { updateAndShowImgsBannerModal(img); });

  document.querySelectorAll('[data-imgs-banner="nav-btn"]').forEach(navBtn => {
    navBtn.addEventListener('click', () => {
      slideImgsBanner(navBtn);
    });
  });

  toggleArrowsImgsBanners();
});

window.addEventListener('resize', () => {
  toggleArrowsImgsBanners();
});

function closeImgsBannerModal(closeBtn) {
  closeBtn.addEventListener('click', () => {
    const modal = closeBtn.closest('[data-imgs-banner="modal"]');
    modal.style.display = 'none';
  });
}

function updateAndShowImgsBannerModal(img) {
  img.addEventListener('click', () => {
    const modal = img.closest('[data-imgs-banner="container"]').nextElementSibling;

    modal.querySelector('[data-imgs-banner="modal-img"]').src = img.querySelector('img').src;

    modal.style.display = 'flex';
  });
}

function slideImgsBanner(navBtn) {
  let dir = 0;
  if (navBtn.classList.contains('nav--left')) {
    dir = -1;
  } else if (navBtn.classList.contains('nav--right')) {
    dir = 1;
  }

  const imgsBannerContainer = navBtn.closest('[data-imgs-banner="container"]');
  const imgsBannerContent = imgsBannerContainer.querySelector('[data-imgs-banner="content"]');
  const sizeToMove = imgsBannerContent.querySelector('[data-imgs-banner="img"]').clientHeight;
  const scrollMargin = 2;
  const scrollMax = imgsBannerContent.scrollWidth - (imgsBannerContainer.clientWidth + scrollMargin);
  const scrollMin = sizeToMove - scrollMargin;

  imgsBannerContent.scrollBy({
    left: sizeToMove * dir,
    behavior: 'smooth'
  });

  if (dir === 1 && imgsBannerContent.scrollLeft >= scrollMax) {
    imgsBannerContent.scrollTo({
      left: 0,
      behavior: 'smooth'
    });
  } else if (dir === -1 && imgsBannerContent.scrollLeft <= scrollMin) {
    imgsBannerContent.scrollTo({
      left: scrollMax,
      behavior: 'smooth'
    });
  }
}

function toggleArrowsImgsBanners() {
  const banners = document.querySelectorAll('[data-imgs-banner="container"]');
  banners.forEach(banner => {
    const content = banner.querySelector('[data-imgs-banner="content"]');
    const arrows = banner.querySelectorAll('[data-imgs-banner="nav-btn"]');

    if (content.scrollWidth > banner.clientWidth) {
      arrows.forEach(arrow => {
        arrow.style.display = 'flex';
      });
    } else {
      arrows.forEach(arrow => {
        arrow.style.display = 'none';
      });
    }
  });
}
