import Toastify from 'toastify-js';

function showNotice(message) {
  Toastify({
    text: message,
    className: 'bg--gradient-r-success radius-3',
    duration: 5000,
    close: true,
    escapeMarkup: false
  }).showToast();
}

function showAlert(message) {
  Toastify({
    text: message,
    className: 'bg--gradient-r-danger radius-3',
    duration: -1,
    close: true,
    escapeMarkup: false
  }).showToast();
}

window.showNotice = showNotice;
window.showAlert = showAlert;
