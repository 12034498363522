// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../stylesheets/application';
import 'general/navbar';
import 'general/imgs_banner';
import 'general/toasts';
import 'general/elements_search';
import 'general/custom_moment';
import 'general/booking_steps';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

require('cocoon-js-vanilla');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true);
// eslint-disable-next-line no-unused-vars
const imagePath = (name) => images(name, true);

require('trix');
require('@rails/actiontext');

const moment = require('moment');
moment.locale(['en-gb', 'pt']);
global.moment = moment;
window.moment = moment;
