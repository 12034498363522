const moment = require('moment/moment');

function setMomentLocale() {
  let locale = 'en';

  if (document.querySelector('[data-locale]')) {
    locale = document.querySelector('[data-locale]').dataset.locale;
  }

  if ((locale === 'en' && moment.locale() !== 'en-gb') || (locale !== 'en' && moment.locale() !== locale)) {
    if (locale === 'pt') {
      // eslint-disable-next-line no-undef
      moment.locale('pt');
    } else {
      // eslint-disable-next-line no-undef
      moment.locale('en-gb');
    }
  }
}

document.addEventListener('turbolinks:load', function() {
  setMomentLocale();
});

function formatedDateTime(date, format = 'default') {
  date = new Date(date).toISOString();
  setMomentLocale();
  if (format === 'short') {
    // eslint-disable-next-line no-undef
    return moment(date).format('HH:mm, DD MMMM');
  } else {
    // eslint-disable-next-line no-undef
    return moment(date).format('YYYY-MM-DD HH:mm:ss') + ' UTC';
  }
}
global.formatedDateTime = formatedDateTime;
window.formatedDateTime = formatedDateTime;

function formatedDate(date) {
  date = new Date(date).toISOString();
  setMomentLocale();
  // eslint-disable-next-line no-undef
  return (moment(date).format('dddd, LL'));
}
global.formatedDate = formatedDate;
window.formatedDate = formatedDate;

function formatedTime(date) {
  date = new Date(date).toISOString();
  setMomentLocale();
  // eslint-disable-next-line no-undef
  return moment(date).format('HH:mm');
}
global.formatedTime = formatedTime;
window.formatedTime = formatedTime;
