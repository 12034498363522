// — HTML CHANGES
function updateNightField(nightField, nNights) {
  if (nNights != null) {
    // View Isn't Updated Yet so you need to tell the number of nights
    nightField.value = nNights;
  } else {
    // Can Calculate the Number of Nights Through View's Elements
    const step = nightField.closest('[data-booking-object = step]');
    // eslint-disable-next-line no-undef
    const nextStep = getNextSibling(step, '[data-booking-object = step]');

    // eslint-disable-next-line no-undef
    const stepDate = moment(getPrevSibling(step, '[data-booking-object = date]').dataset.bookingDate);
    // eslint-disable-next-line no-undef
    const nextDate = moment(getPrevSibling(nextStep, '[data-booking-object = date]').dataset.bookingDate);

    // eslint-disable-next-line no-undef
    nightField.value = moment(nextDate).startOf('day').diff(moment(stepDate).startOf('day'), 'days');
  }
}
global.updateNightField = updateNightField;
window.updateNightField = updateNightField;

function updateOptionsAndEndDate(step, startDate, duration, startDatesOptions) {
  // eslint-disable-next-line no-undef
  startDate = moment(startDate);
  // eslint-disable-next-line no-undef
  const endDate = moment(startDate).add(duration, 'minutes');
  // Elements to update
  const startDateField = step.querySelector('[data-b-step-field = start-date]');
  const endDateField = step.querySelector('[data-b-step-field = end-date]');
  const endDateInfo = step.querySelector('[data-b-step-info = end-date]');

  const timeOptions = getStartDatesOptions(startDate, startDatesOptions);

  if (timeOptions) {
    // eslint-disable-next-line no-undef
    endDateField.value = formatedDateTime(endDate);

    // eslint-disable-next-line no-undef
    if (moment(endDate).startOf('day').diff(moment(startDate).startOf('day'), 'days') > 0) {
      // eslint-disable-next-line no-undef
      endDateInfo.innerHTML = formatedDateTime(endDate, 'short');
    } else {
      // eslint-disable-next-line no-undef
      endDateInfo.innerHTML = formatedTime(endDate);
    }

    // Remove old options
    startDateField.innerHTML = '';
    // Add new options to select
    timeOptions.forEach(time => {
      // eslint-disable-next-line no-undef
      time = moment(time);
      // set selected attribute if it's startDate
      let selected = '';
      // eslint-disable-next-line no-undef
      if (moment(time).isSame(startDate)) {
        selected = '\'selected=selected\'';
      }
      // add option to select
      // eslint-disable-next-line no-undef
      const option = '<option ' + selected + ' value=\'' + formatedDateTime(time) + '\'>' + formatedTime(time) + '</option>';
      startDateField.insertAdjacentHTML('beforeend', option);
    });
  }
}

function updateConnectionObject(currentStep, newStepInfo) {
  // eslint-disable-next-line no-undef
  const nextBookingObject = getNextSibling(currentStep, '[data-booking-object]');
  let newObject = document.createElement('div');
  newObject.classList.add('border-left', 'border-dashed', 'p-t-4', 'm-l-x6');
  newObject.dataset.bookingObject = 'connection';

  // If new step is on other day
  if (newStepInfo.newDay) {
    newObject = document.createElement('h5');
    newObject.classList.add('capitalize', 'm-t-5', 'm-b-3');
    newObject.dataset.bookingObject = 'date';
    // eslint-disable-next-line no-undef
    newObject.dataset.bookingDate = formatedDateTime(moment(newStepInfo.startDate).startOf('day'));
    // eslint-disable-next-line no-undef
    newObject.textContent = formatedDate(moment(newStepInfo.startDate));
  }

  // Update connection/date
  nextBookingObject.after(newObject);
  nextBookingObject.remove();
}
global.updateConnectionObject = updateConnectionObject;
window.updateConnectionObject = updateConnectionObject;

// — LOGIC
function updateSteps(changedField, tourStepsData) {
  const changedStep = changedField.closest('[data-booking-object = step]');
  // eslint-disable-next-line no-undef
  const nextSteps = getAllNextSiblings(changedStep, '[data-booking-object = step]');
  // NextSteps needs to include the changedStep as first step
  // so it can be changed too
  nextSteps.unshift(changedStep);

  // Set variables with changedStep information
  let stepId = changedStep.dataset.bStepTourStepId;
  let stepDuration = tourStepsData[stepId].duration;
  let stepStartDatesOptions = tourStepsData[stepId].startDates;
  let stepStartDate = changedStep.querySelector('[data-b-step-field = start-date]').value;
  const stepStartTime = stepStartDate.split(' ')[1].split(':');
  // eslint-disable-next-line no-undef
  stepStartDate = moment(stepStartDate).startOf('day').set({ hours: stepStartTime[0], minutes: stepStartTime[1] });

  // eslint-disable-next-line no-undef
  let stepEndDate = moment(stepStartDate).add(stepDuration, 'minutes');
  let nNights = changedStep.querySelector('[data-b-step-field = nights]').value;

  for (let i = 0; i < nextSteps.length; i++) {
    const currentStep = nextSteps[i];

    // If currentStep === changedStep --> Block all previous options + Update End Date Info ( Just for Visual Coherence)
    if (i === 0) {
      // 1 - Block previous options
      // eslint-disable-next-line no-undef
      const prevSteps = getAllPrevSiblings(currentStep, '[data-booking-object = step]');
      prevSteps.forEach((prevStep) => {
        const prevStepId = prevStep.dataset.bStepTourStepId;
        const prevStepDuration = tourStepsData[prevStepId].duration;
        const prevStepStartDatesOptions = tourStepsData[prevStepId].startDates;
        let prevStepStartDate = prevStep.querySelector('[data-b-step-field = start-date]').value;
        const prevStepStartTime = prevStepStartDate.split(' ')[1].split(':');
        // eslint-disable-next-line no-undef
        prevStepStartDate = moment(prevStepStartDate).startOf('day').set({ hours: prevStepStartTime[0], minutes: prevStepStartTime[1] });
        updateOptionsAndEndDate(prevStep, prevStepStartDate, prevStepDuration, prevStepStartDatesOptions);
      });
      // 2 - Update End Date Info
      const endDateInfo = currentStep.querySelector('[data-b-step-info = end-date]');
      // eslint-disable-next-line no-undef
      endDateInfo.innerHTML = formatedDateTime(stepEndDate, 'short');
    } else { // If currentStep === nextStep --> Update options
      updateOptionsAndEndDate(currentStep, stepStartDate, stepDuration, stepStartDatesOptions);
    }

    // If NOT Last Step
    if (i < nextSteps.length - 1) {
      // Update variables with next step information
      stepId = nextSteps[i + 1].dataset.bStepTourStepId;
      stepDuration = tourStepsData[stepId].duration;
      stepStartDatesOptions = tourStepsData[stepId].startDates;
      // Next step's start date needs to be >= current step's end date

      let newStepStartDate = stepEndDate;
      let newStepOnNewDay = false;

      if (currentStep === changedStep && changedField.dataset.bStepField === 'nights' && nNights != null && nNights > 0) {
        // eslint-disable-next-line no-undef
        newStepStartDate = moment(newStepStartDate).add(nNights, 'days').startOf('day');
        newStepOnNewDay = true;
      }

      const newStepInfo = getNextStepStartInfo(newStepStartDate, stepStartDatesOptions, newStepOnNewDay);

      newStepStartDate = newStepInfo.startDate;

      // eslint-disable-next-line no-undef
      nNights = moment(newStepStartDate).startOf('day').diff(moment(stepStartDate).startOf('day'), 'days');
      stepStartDate = newStepStartDate;
      // eslint-disable-next-line no-undef
      stepEndDate = moment(stepStartDate).add(stepDuration, 'minutes');

      // Update the current step's night field and connection/date
      updateNightField(currentStep.querySelector('[data-b-step-field = nights]'), nNights);
      updateConnectionObject(currentStep, newStepInfo);
    }
  }
}
global.updateSteps = updateSteps;
window.updateSteps = updateSteps;

function getNextStepStartInfo(newStartDate, startDatesOptions, newDay = false) {
  // eslint-disable-next-line no-undef
  let startDate = moment(newStartDate);
  const timeOptions = getStartDatesOptions(startDate, startDatesOptions);

  // If there's options for that day
  if (timeOptions.length > 0) {
    // Find the first hour that day that isn't before the startDate
    // eslint-disable-next-line no-undef
    const closestTime = timeOptions.find(time => !moment(time).isBefore(newStartDate));

    if (closestTime) {
      // If found, the new startDate is that hour
      startDate = closestTime;
    } else {
      newDay = true;
      // eslint-disable-next-line no-undef
      startDate = moment(startDate).add(1, 'days').startOf('day');
      return getNextStepStartInfo(startDate, startDatesOptions, newDay);
    }
  } else {
    // If not, it looks for an hour next day
    newDay = true;
    // eslint-disable-next-line no-undef
    startDate = moment(startDate).add(1, 'days').startOf('day');
    return getNextStepStartInfo(startDate, startDatesOptions, newDay);
  }

  return {
    startDate: startDate,
    newDay: newDay
  };
}
global.getNextStepStartInfo = getNextStepStartInfo;
window.getNextStepStartInfo = getNextStepStartInfo;

function getStartDatesOptions(strDate, startDatesOptions) {
  let options = null;
  // eslint-disable-next-line no-undef
  if (moment(strDate).weekday() === 5 || moment(strDate).weekday() === 6) {
    options = startDatesOptions.weekends;
  } else {
    options = startDatesOptions.weekdays;
  }

  let finalStartDatesOptions = [];
  if (options) {
    // Turn weekdays/weekends hours to dates
    options.forEach(time => {
      // eslint-disable-next-line no-undef
      let d = moment(strDate);
      // eslint-disable-next-line no-undef
      d = moment(d).set({ hours: parseInt(time.split(':')[0]), minutes: parseInt(time.split(':')[1]) });
      // Add date to finalStartDatesOptions if date is NOT before strDate
      // eslint-disable-next-line no-undef
      if (!moment(d).isBefore(moment(strDate))) {
        finalStartDatesOptions.push(d);
      }
    });
    finalStartDatesOptions = finalStartDatesOptions.sort();
  }
  return finalStartDatesOptions;
}
